import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-transitions.css";
import "../css/index.css"

import { Swiper } from 'swiper'
import { Navigation, Pagination } from 'swiper/modules';
import { Timer } from "easytimer.js";
import ScrollSmooth from "./ScrollSmooth";

// document.getElementById("seconds").style.width = document.getElementById("seconds").offsetWidth + "px";

function setOfferHeight() {
	const offerBlock = document.getElementById('offer');

	if (offerBlock === null) {
		return false;
	}
	
	const vh = window.innerHeight * 0.01; // 1% від висоти вікна
	const vw = window.innerWidth * 0.01; // 1% від ширини вікна
	
	const heightInPixels = (100 * vh) - (20 * vw) - (16 * vw);
	
	offerBlock.style.minHeight = heightInPixels + 'px';
}

window.addEventListener('load', setOfferHeight);

const swiperCreatives = new Swiper('.swiper-creatives', {
	modules: [Pagination],
	slidesPerView: 1.8,
	grabCursor: true,
	initialSlide: 1,
	centeredSlides: true,
	lazy: true,
	breakpoints: {
		1024: {
			slidesPerView: 3,
			centeredSlides: false,
			initialSlide: 0,
		},
	},
	pagination: {
		el: ".swiper-pagination",
		dynamicBullets: true,
	},
	on: {
		init: function () {
			this.wrapperEl.classList.remove("flex")
			this.slides.forEach(el=>el.classList.remove("w-[56%]","lg:w-[34%]"));
		},
	},
});

document.addEventListener("DOMContentLoaded", () => {

	document.getElementById("menu-button").addEventListener("click", () => {
		document.getElementById("body").classList.toggle("burger-open");
		document.getElementById("menu-button").classList.toggle("cross");
	});

	const swiper = new Swiper('.swiper-gallery', {
		modules: [Pagination, Navigation],
		slidesPerView: 1.8,
		initialSlide: 1,
		centeredSlides: true,
		breakpoints: {
			1024: {
				slidesPerView: 3,
			},
		},
		pagination: {
			el: ".swiper-pagination",
			dynamicBullets: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});

	
	
	new ScrollSmooth({
		headerHeight: 0,
		navigationHeight: 0,
	});

	setTimer()

	changeLazyImageSrc()

	// Функція для зміни src з placeholder на raw
	function changeImageSrc(img) {
		const src = img.src.replace("placeholder", "raw");
		fetch(src).then(response => {
			if (response.ok) {
				img.src = src;
			}
		}).catch(error => {
			console.error("Failed to load image", error);
		}).finally(() => {
			// 
		});
	}

	// Створюємо інтерсекшн спостерігач
	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				// Коли зображення стає видимим, змінюємо його src
				changeImageSrc(entry.target);
				// При необхідності можна відключити спостереження після того, як src змінено
				observer.unobserve(entry.target);
			}
		});
	}, {
		threshold: 0.1 // Викликається, коли хоча б 10% зображення потрапляють в область видимості
	});

	// Знайдемо всі зображення
	document.querySelectorAll(".swiper-creatives img")?.forEach(img => {
		// Спостерігаємо за кожним зображенням
		observer.observe(img);
	});

});

// timer.addEventListener("targetAchieved", function (e) {
//     document.querySelector("[data-countdown]").style.display = "none";
//     document.querySelector("[data-countdow-achieved]").style.display = "block";
// });

setTimeout(async () => {

	const isNeed = document.querySelectorAll("[data-lg-gallery-thumbnail]").length > 0 || document.querySelectorAll("[data-lg-video-after-click]").length > 0;

	if (!isNeed) {
		return false;
	}

	const lightGalleryModule = await import("lightgallery");
	const lgVideoModule = await import("lightgallery/plugins/video");
	const lgZoomModule = await import("lightgallery/plugins/zoom");
	const lgThumbnailModule = await import("lightgallery/plugins/thumbnail");

	const lightGallery = lightGalleryModule.default;
	const lgVideo = lgVideoModule.default;
	const lgZoom = lgZoomModule.default;
	const lgThumbnail = lgThumbnailModule.default;

	setLg()
	setLgVideoAfterClick()

	document.addEventListener("swiper-reviews-updated", setLgVideoAfterClick)
	document.addEventListener("cargo-tracking-lot-updated", setLg)

	function setLg() {
		document.querySelectorAll("[data-lg-gallery-thumbnail]").forEach((el) => {
			const gallery = lightGallery(el.querySelector("[data-lg-gallery-thumbnail-wrap]"), {
				licenseKey: "0D0A7BAD-63644EDA-8ADD2380-F0EEFDEE",
				plugins: [lgZoom, lgThumbnail],
				thumbnail: true,
				zoom: true,
				actualSize: false,
				showZoomInOutIcons: true,
				download: false,
				mobileSettings: { showCloseIcon: true },
			});
		});
	}

	function setLgVideoAfterClick() {
		document.querySelectorAll("[data-lg-video-after-click]")?.forEach((el) => {
			if (el.lightGallery) {
				return false;
			}
			el.lightGallery = lightGallery(el, {
				licenseKey: "0D0A7BAD-63644EDA-8ADD2380-F0EEFDEE",
				selector: "this",
				plugins: [lgVideo],
				download: false,
				mobileSettings: { showCloseIcon: true },
			});
			return true;
		});
	}
}, 2000);

function changeLazyImageSrc() {
	const imagesWitoutBG = document.querySelectorAll(".change-me");
	imagesWitoutBG?.forEach(el => {
		el.style.background = `url(${el.nextElementSibling.src}) no-repeat center center / cover`;
	})

}

function setTimer() {
	const isNeed = document.getElementById("timer");
	
	if (!isNeed) {
		return false;
	}

	const timer = new Timer();
	timer.start({
		countdown: true,
		startValues: {
			"hours": 10,
			"minutes": 44,
			"seconds": 35,
		},
		// startValues: JSON.parse(
		//     document.querySelector("[data-start-values]").dataset.startValues
		// ),
	});

	timer.addEventListener("secondsUpdated", function (e) {
		document.getElementById("hours").innerHTML = timer.getTimeValues().hours;
		document.getElementById("minutes").innerHTML = timer.getTimeValues().minutes;
		document.getElementById("seconds").innerHTML = timer.getTimeValues().seconds;
	});

	document.getElementById("timer-pause").addEventListener("click", function () {
		timer.pause();
		document.getElementById("timer-pause").classList.add("hidden");
		document.getElementById("timer-play").classList.remove("hidden");
	});

	document.getElementById("timer-play").addEventListener("click", function () {
		timer.start();
		document.getElementById("timer-pause").classList.remove("hidden");
		document.getElementById("timer-play").classList.add("hidden");
	});

	document.getElementById("timer-remove").addEventListener("click", function () {
		document.getElementById("timer").classList.add("opacity-0","pointer-events-none");
		document.getElementById("timer").remove();
		timer.stop();
	});
}